.App {
  text-align: center;
}

.App-logo {
  color: #41a148;
  
  pointer-events: none;
}

.form {
  background-image: url("grass-free-texture.jpg");
  background-color: #41a148;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(41, 124, 69);
}

.form-item {
  
  padding:5px;
  background-color: rgb(183, 248, 201);
  color:#000000;
  
  
}
.App-link {
  color: #61dafb;
}
.ReCAPTCHA {
  justify-content: center;
  padding-top:5px;
  padding-bottom:5px;
}